import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/group-message/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function search (data) {
  return request({
    url: '/message/search',
    method: 'post',
    data
  })
}
export function preview (data) {
  return request({
    url: '/message/preview',
    method: 'post',
    data
  })
}
export function destroy (_id) {
  return request({
    url: '/message/' + _id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/message/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
